import './App.css';
import { useEffect } from "react";
import $ from 'jquery';
import axios from 'axios';
import About from './Components/About';
import Banner from './Components/Banner';
import Blog from './Components/Blog';
import { Counter } from './Components/Counter';
import Fourservices from './Components/Fourservices';
import Header from './Components/Header/Header';
import Services from './Components/Services';
import Testimonial from './Components/Testimonial';
import Footer from './Components/Footer/Footer';
import { CallToAction} from './Components/CallToAction';
import {BrowserRouter,Routes,Route,}
from "react-router-dom";
import { InnerBanner } from './Components/InnerBanner';
import { InnerAbout } from './Components/InnerAbout';
import WhyChooseUs from './Components/WhyChooseUs';
import { OurSolutions } from './Components/OurSolutions';
import { InnerContact } from './Components/InnerContact';
import { Industries } from './Components/Industries';
import { MainBlog } from './Components/MainBlog';
import { MainContact } from './Components/MainContact';
import { Map } from './Components/Map';
import { SixCompo } from './Components/workday/SixCompo';
import { Workdayboxleft } from './Components/workday/Workdayboxleft';
import { Client } from './Components/workday/Client';
import {AgileTransformation} from './Components/Inner-service/AgileTransformation';
import { BlockChainDevelopment } from './Components/Inner-service/BlockChainDevelopment';
import { StartupItSupport } from './Components/Inner-service/StartupItSupport';
import { ImprovedROI } from './Components/Inner-service/ImprovedROI';
import { DevopsMethodology } from './Components/Inner-service/DevopsMethodology';
import { ApplicationDevelopment } from './Components/Inner-service/ApplicationDevelopment';
import { ApplicationMaintenance } from './Components/Inner-service/ApplicationMaintenance';
import { IOTDevelopment } from './Components/Inner-service/IOTDevelopment';
import { SmartAutomationStrategies } from './Components/Inner-service/SmartAutomationStrategies';
import { ManagedOutsourcingService } from './Components/Inner-service/ManagedOutsourcingService';
import { InfluentialMarketing } from './Components/Inner-service/InfluentialMarketing';
import { DigitalMarketing } from './Components/Inner-service/DigitalMarketing';
import { StartupIncubation } from './Components/Inner-service/StartupIncubation';
import { SoftwareDesignAndDevelopment } from './Components/Inner-service/SoftwareDesignAndDevelopment';
import { OnlineReputationManagment } from './Components/Inner-service/OnlineReputationManagment';
import { DigitalConsultation } from './Components/Inner-service/DigitalConsultation';
import { HireDeveloper } from './Components/Inner-service/HireDeveloper';
import { IntegratedBrandManagement } from './Components/Inner-service/IntegratedBrandManagement';
import { ReduceInfrastructureCost } from './Components/Inner-service/ReduceInfrastructureCost';
import { Webstorytec } from './Components/Webstorytec'; 
import { MyStoryinner } from './Components/MyStoryinner'; 


function App() {
   useEffect(() => {

      axios.get('https://techsaga.us/API/api/metaData?url='+window.location.href).then((response) => {
         	console.log(response.data.status);
         	var res = response.data;
         	if(res.status==true)
         	{
				console.log(res.data.url);
				$('meta[name=description]').attr('content',res.data.description);
				$('meta[name=keywords]').attr('content',res.data.keywords);
				$('meta[name="twitter:card"]').attr('content',res.data.twitter_card);
				$('meta[name="twitter:site"]').attr('content',res.data.twitter_site);
				$('meta[name="twitter:creator"]').attr('content',res.data.twitter_creater);
				$('meta[property="og:title"]').attr('content',res.data.og_title);
				$('meta[property="og:description"]').attr('content',res.data.og_description);
				$('meta[property="og:image"]').attr('content',res.data.og_image_url);
				$('meta[property="og:url"]').attr('content',res.data.url);
				$('title').text(res.data.title);
         	}
      	}).catch((error) => {
         	console.log(error);
      	});
   });

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
        {/* Home page Started  */}
          <Route path="/" element={<><Banner /><About />
            <Counter />
            <Fourservices />
            <Services activeCard={6}/>
            <InnerContact/>
            {/* <Blog /> */}
            <Testimonial />
            <CallToAction />
            {/* <BlogCompo /> */}
            </>}>
          </Route>
        {/* About page Started  */}
          <Route path="/about" element={<>
             <InnerBanner heading={"About"}/> 
             <InnerAbout />
             <WhyChooseUs />
             <OurSolutions />
             <InnerContact/>
          </>}>
          </Route>
          <Route path="/about2" element={<>
             <InnerBanner heading={"About"}/> 
             <InnerAbout />
             <WhyChooseUs />
             <OurSolutions />
             <InnerContact/>
          </>}>
          </Route>
             {/* workday page Started  */}
             <Route path="/workday" element={<>
             <InnerBanner heading={"Workday"}/> 
             <SixCompo/>
             <Workdayboxleft/>
             <Client/>
          </>}>
          </Route>
          {/* service page Started  */}
          <Route path="/service" element={<>
             <InnerBanner heading={"services"}/> 
             <Services activeCard={19}/>
          </>}>
          </Route>
          <Route path="/webstory" element={<>
             <InnerBanner heading={"Services"}/> 
             <Webstorytec />
          </>}>
          </Route>
          <Route path="/mystory" element={<>
             <InnerBanner heading={"Services"}/> 
             <MyStoryinner />
          </>}>
          </Route>
            {/* industries page Started  */}
            <Route path="/industries" element={<>
             <Industries heading={"Industries"}/> 
          </>}>
          </Route>
            {/* Main blog page Started  */}
            {/* <Route path="/mainblog" element={<>
             <InnerBanner heading={"Blog"}/> 
             <MainBlog/>
          </>}>
          </Route> */}
           <Route path="/blog" element={<>
             {/* <InnerBanner heading={"Contact"}/>  */}
             <InnerBanner heading={"Blog"}/> 
             <MainBlog/>
             {/* <MainContact/>
             <InnerContact />
             <Map /> */}
          </>}>
          </Route>
           {/* Main contact page Started  */}
           <Route path="/contact" element={<>
             <InnerBanner heading={"Contact"}/> 
             <MainContact/>
             <InnerContact />
             <Map />
          </>}>
          </Route>
            {/* Agile Transformation contact page Started */}
            <Route path="/agile" element={<>
             <InnerBanner heading={"Agile Transformation"}/> 
             <AgileTransformation/>
          </>}>
          </Route>
                {/* Startup It Support page Started */}
                <Route path="/Start-up-it-support" element={<>
             <InnerBanner heading={"Startup IT Support"}/> 
             <StartupItSupport/>
          </>}>
          </Route>
            {/* Block chain development page Started */}
            <Route path="/blockchain-development" element={<>
             <InnerBanner heading={"BlockChain Development"}/> 
             <BlockChainDevelopment/>
          </>}>
          </Route>
          {/* Block chain development page Started */}
          <Route path="/ImprovedROI" element={<>
             <InnerBanner heading={"Improved ROI"}/> 
             <ImprovedROI/>
          </>}>
          </Route>
          <Route path="/devops-methodology" element={<>
             <InnerBanner heading={"Devops Methodology"}/> 
             <DevopsMethodology/>
          </>}>
          </Route>
          <Route path="/application-development" element={<>
             <InnerBanner heading={"Application Development"}/> 
             <ApplicationDevelopment/>
          </>}>
          </Route>
          <Route path="/application-maintenance" element={<>
             <InnerBanner heading={"Application Maintenance"}/> 
             <ApplicationMaintenance/>
          </>}>
          </Route>
          <Route path="/iot-development" element={<>
             <InnerBanner heading={"IOT Development"}/> 
             <IOTDevelopment/>
          </>}>
          </Route>
          <Route path="/smart-automation-strategies" element={<>
             <InnerBanner heading={"Smart Automation Strategies"}/> 
             <SmartAutomationStrategies/>
          </>}>
          </Route>
          <Route path="/managed-outsourcing-service" element={<>
             <InnerBanner heading={"Managed Outsourcing Service"}/> 
             <ManagedOutsourcingService/>
          </>}>
          </Route>
          <Route path="/influential-marketing" element={<>
             <InnerBanner heading={"Influential Marketing"}/> 
             <InfluentialMarketing/>
          </>}>
          </Route>
          <Route path="/digital-marketing" element={<>
             <InnerBanner heading={"Digital Marketing"}/> 
             <DigitalMarketing/>
          </>}>
          </Route>
          <Route path="/startup-incubation" element={<>
             <InnerBanner heading={"Startup Incubation"}/> 
             <StartupIncubation/>
          </>}>
          </Route>
          <Route path="/software-design-and-development" element={<>
             <InnerBanner heading={"Software Design And Development"}/> 
             <SoftwareDesignAndDevelopment/>
          </>}>
          </Route>
          <Route path="/online-reputation-managment" element={<>
             <InnerBanner heading={"Online Reputation Management"}/> 
             <OnlineReputationManagment/>
          </>}>
          </Route>
          <Route path="/digital-xonsultation" element={<>
             <InnerBanner heading={"Digital Consultation"}/> 
             <DigitalConsultation/>
          </>}>
          </Route>
          <Route path="/ImprovedROI" element={<>
             <InnerBanner heading={"Improved ROI"}/> 
             <ImprovedROI/>
          </>}>
          </Route>
          <Route path="/hire-developer" element={<>
             <InnerBanner heading={"Hire Developer"}/> 
             <HireDeveloper/>
          </>}>
          </Route>
          <Route path="/integrated-brand-management" element={<>
             <InnerBanner heading={"Integrated Brand Management"}/> 
             <IntegratedBrandManagement/>
          </>}>
          </Route>
          <Route path="/Reduce-Infrastructure-Cost" element={<>
             <InnerBanner heading={"Reduce InfrastructureCost"}/> 
             <ReduceInfrastructureCost/>
          </>}>
          </Route>
          
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* <Banner />
       */}
    </>
  );
}

export default App;

